body, html, #root {
	min-height: 100%;
	height: 100%;
}

body {
	background-color: #E0ECDE;
}

a {
	text-decoration: none;
	color: black;
}